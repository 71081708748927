// src/components/ProductList.js
import React, { useState } from 'react';
import './ProductList.css';

function ProductList({ lineas, setSelectedSerie }) {
  const [expandedLineaId, setExpandedLineaId] = useState(null);
  const [selectedSerieId, setSelectedSerieId] = useState(null);

  const handleLineaClick = (lineaId) => {
    if (expandedLineaId === lineaId) {
      setExpandedLineaId(null); // Collapse if already expanded
    } else {
      setExpandedLineaId(lineaId); // Expand the new Linea
    }
  };

  const handleSerieClick = (serie) => {
    setSelectedSerie(serie);
    setSelectedSerieId(serie.id); // Mark the series as selected
  };

  const handleReset = () => {
    setSelectedSerie(null); // Reset selected serie in parent component
    setExpandedLineaId(null); // Collapse all lineas
    setSelectedSerieId(null); // Clear selected serie
  };

  return (
    <div className="product-list-container">
      {/* Collapsible list for desktop */}
      <ul className="product-list-desktop-list">
        <h2 className="product-list-title">BUSCAR</h2>
        {lineas.map((linea) => (
          <li key={linea.id}>
            <div
              className={`product-list-linea-header ${
                expandedLineaId === linea.id ? 'selected-linea' : ''
              }`}
              onClick={() => handleLineaClick(linea.id)}
            >
              <p>{linea.name}</p>
              <span className="material-icons">expand_more</span> {/* Arrow icon */}
            </div>
            {expandedLineaId === linea.id && (
              <ul className="product-list-series-list">
                {linea.series.map((serie) => (
                  <li
                    key={serie.id}
                    onClick={() => handleSerieClick(serie)}
                    className={selectedSerieId === serie.id ? 'selected-serie' : ''}
                  >
                    {serie.name}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      {/* Reset button */}
      <button className="product-list-reset-button" onClick={handleReset}>
        RESTABLECER
      </button>
    </div>
  );
}

export default ProductList;
