// src/components/Contacto.js
import React, { useState, useEffect } from 'react';
import './Contacto.css';
import PageBanner from '../components/PageBanner';
import ContactForm from '../components/ContactForm';
import ContactCard from '../components/ContactCard';
import ContactMap from '../components/ContactMap';
import LandingFooter from '../components/LandingFooter';

const Contacto = () => {
  const [selectedOficina, setSelectedOficina] = useState(null); // Estado para almacenar la oficina seleccionada

  // Scroll to the top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Función que será pasada a ContactCard y usada para actualizar la oficina seleccionada
  const handleOfficeChange = (oficina) => {
    setSelectedOficina(oficina); // Actualiza la oficina seleccionada cuando cambie
  };

  return (
    <div className="contact-page">
      <div className="contact-page-banner">
        <PageBanner />
      </div>
      <div className="contact-page-content">
        <div className="contact-page-information">
          <div className="contact-page-card">
            {/* Pasar handleOfficeChange a ContactCard */}
            <ContactCard onOfficeChange={handleOfficeChange} />
          </div>
          <div className="contact-page-form">
            {/* Pasar selectedOficina a ContactForm */}
            <ContactForm selectedOficina={selectedOficina} />
          </div>
        </div>
      </div>
      <div className="contact-page-map">
        {/* Pasar selectedOficina a ContactMap */}
        <ContactMap oficina={selectedOficina} />
      </div>
      <div className="contact-page-footer">
        <LandingFooter />
      </div>
    </div>
  );
};

export default Contacto;
