// src/components/LandingFooter.js

import React from 'react';
import './LandingFooter.css';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import logo from '../images/logo-footer.png';

const LandingFooter = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <img src={logo} alt="ZoomLion Logo" className="logo" />
        <div className="footer-info">
          <div className="social-media">
            <a href="https://www.facebook.com/profile.php?id=100093352872036" className="icon-wrapper"><FaFacebookF className="social-icon" /></a>
            <a href="https://www.instagram.com/zoomlionlatam/" className="icon-wrapper"><FaInstagram className="social-icon" /></a>
            <a href="https://www.linkedin.com/company/zoomlion-latam/" className="icon-wrapper"><FaLinkedinIn className="social-icon" /></a>
            <a href="https://www.youtube.com/@zoomlionglobal706" className="icon-wrapper"><FaYoutube className="social-icon" /></a>
          </div>
        </div>
      </div>
      <p>© 2024 ZoomLion Heavy Industry SAC</p>
    </div>
  );
};

export default LandingFooter;
