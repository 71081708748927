import React from 'react';
import './LandingSendButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const LandingSendButton = ({ onClick, isSending }) => {
  return (
    <div
      className={`landing-send-container ${isSending ? 'sending' : ''}`}
      onClick={!isSending ? onClick : null}
    >
      <div className="landing-send-content">
        <span>{isSending ? 'ENVIANDO' : 'ENVIAR'}</span>
      </div>
      {!isSending && (
        <div className="landing-send-arrow">
          <FontAwesomeIcon icon={faAngleRight} className="send-arrow-icon" />
        </div>
      )}
    </div>
  );
};

export default LandingSendButton;
