import React from 'react';
import { Link } from 'react-router-dom';
import './PageNavbar.css'; // Use a specific CSS file for PageNavbar
import logo from '../images/logo.webp';

const PageNavbar = () => {
  return (
    <nav className="page-navbar">
      <div className="page-navbar-content">
        <div className="page-navbar-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
      {/*<div className="page-navbar-links">
          <Link to="/">HOME</Link>
          <Link to="/contacto">CONTACTO</Link>
          <Link to="/productos">PRODUCTOS</Link> 
        </div> */}
      </div>
    </nav>
  );
};

export default PageNavbar;
