import React, { useState } from 'react';
import './LandingContact.css';
import LandingSendButton from './LandingSendButton';
import { RevolvingDot } from 'react-loader-spinner';

const LandingContact = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    pais: 'Perú',
    telefono: '',
    mensaje: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setFadeOut(true);
        setTimeout(() => {
          setIsSubmitted(true);
        }, 1000);
        console.log('Email sent successfully!');
      } else {
        const errorData = await response.json();
        console.error('Failed to send email:', errorData);
        setIsSending(false);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setIsSending(false);
    }
  };

  const handleClick = () => {
    document.querySelector('.landing-contact-form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  return (
    <div className="landing-contact-wrapper">
      {isSubmitted ? (
        <h3 className="landing-contact-thankyou fade-in">¡Recibimos tu mensaje! <br /> En breve nos pondremos en contacto.</h3>
      ) : (
        <div className={`landing-contact-content ${fadeOut ? 'fade-out' : ''}`}>
          <h2 className="landing-contact-title">CONTACTO</h2>
          <form className="landing-contact-form" onSubmit={handleSubmit}>
            <div className="landing-contact-row">
              <div className="landing-contact-field">
                <select
                  name="pais"
                  value={formData.pais}
                  onChange={handleChange}
                  required
                  className="landing-contact-input"
                >
                  <option value="Argentina">Argentina</option>
                  <option value="Belice">Belice</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Caribe y Bahamas">Caribe y Bahamas</option>
                  <option value="Chile">Chile</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Honduras">Honduras</option>
                  <option value="México">México</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Panamá">Panamá</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Perú">Perú</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Venezuela">Venezuela</option>
                </select>
              </div>
              <div className="landing-contact-field">
                <input
                  type="text"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  placeholder="Nombre"
                  required
                  className="landing-contact-input"
                />
              </div>
            </div>
            <div className="landing-contact-row">
              <div className="landing-contact-field">
                <input
                  type="number"
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  placeholder="Teléfono"
                  required
                  className="landing-contact-input"
                />
              </div>
              <div className="landing-contact-field">
                <input
                  type="email"
                  name="correo"
                  value={formData.correo}
                  onChange={handleChange}
                  placeholder="Correo electrónico"
                  required
                  className="landing-contact-input"
                />
              </div>
            </div>
            <div className="landing-contact-field landing-contact-field-fullwidth">
              <textarea
                name="mensaje"
                value={formData.mensaje}
                onChange={handleChange}
                placeholder="Mensaje"
                required
                className="landing-contact-textarea"
              />
            </div>
            <div className="landing-contact-button-wrapper">
              <LandingSendButton onClick={handleClick} isSending={isSending} />
            </div>
            {isSending && (
            <div className="landing-contact-loading-animation">
              <RevolvingDot
                visible={true}
                height="30"
                width="30"
                color="#AACE39"
                ariaLabel="revolving-dot-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          )}
          </form>

        </div>
      )}
    </div>
  );
};

export default LandingContact;
