// src/components/ProductGrid.js
import React from 'react';
import ModelCard from './ModelCard';
import './ProductGrid.css';

function ProductGrid() {
  const dummyModels = [
    { name: 'Modelo Dummy 1', weight: 35000, power: 210, capacity: 2.0, image: 'https://picsum.photos/300/200?random=1' },
    { name: 'Modelo Dummy 2', weight: 36000, power: 215, capacity: 2.2, image: 'https://picsum.photos/300/200?random=2' },
    { name: 'Modelo Dummy 3', weight: 37000, power: 220, capacity: 2.5, image: 'https://picsum.photos/300/200?random=3' },
    { name: 'Modelo Dummy 4', weight: 38000, power: 225, capacity: 2.7, image: 'https://picsum.photos/300/200?random=4' }
  ];

  return (
    <div className="product-grid">
      {dummyModels.map((model, index) => (
        <ModelCard
          key={index}
          name={model.name}
          weight={model.weight}
          power={model.power}
          capacity={model.capacity}
          image={model.image}
        />
      ))}
    </div>
  );
}

export default ProductGrid;
