import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import './FullPageWrapper.css';

const FullPageWrapper = ({ children }) => {
  return (
    <ReactFullpage
      licenseKey={'OPEN-SOURCE-GPLV3-LICENSE'}
      scrollingSpeed={1000}
      onLeave={(origin, destination) => {
        const navbar = document.querySelector('.home-navbar');
        console.log(`Leaving section ${origin.index}, entering section ${destination.index}`);

        if (navbar) {
          if (destination.index === 0) {
            navbar.style.backgroundColor = 'transparent';
            navbar.style.color = 'white';
            const links = navbar.querySelectorAll('a');
            links.forEach(link => {
              link.style.color = 'white';
            });
          } else {
            navbar.style.backgroundColor = 'white';
            navbar.style.color = 'black';
            const links = navbar.querySelectorAll('a');
            links.forEach(link => {
              link.style.color = 'black';
            });
          }
        }

        // Trigger map animation when entering the map section (index 2)
        if (destination.index === 1) {
          console.log("Dispatching startAnimation event on document.");
          document.dispatchEvent(new Event('startAnimation'));
        }
      }}
      render={() => (
        <ReactFullpage.Wrapper>
          {children}
        </ReactFullpage.Wrapper>
      )}
    />
  );
};

export default FullPageWrapper;
