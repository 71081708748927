import React, { useEffect, useRef, useState } from 'react';
import './MapSectionHome.css';
import LandingMapWrapper from './LandingMapWrapper';
import CountUp from 'react-countup';

const MapSectionHome = () => {
  const [hasCounted, setHasCounted] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const mapSectionRef = useRef(null);

  const handleCountriesColored = () => {
    console.log("handleCountriesColored triggered: Map animation is fully completed.");
    setAnimationCompleted(true);
    setHasCounted(true);
  };

  useEffect(() => {
    console.log("MapSectionHome mounted.");

    const startAnimationHandler = () => {
      console.log("startAnimationHandler triggered.");
      setHasCounted(true);
    };

    if (mapSectionRef.current) {
      console.log("Adding startAnimation event listener.");
      mapSectionRef.current.addEventListener('startAnimation', startAnimationHandler);
    }

    return () => {
      if (mapSectionRef.current) {
        console.log("Removing startAnimation event listener.");
        mapSectionRef.current.removeEventListener('startAnimation', startAnimationHandler);
      }
    };
  }, []);

  useEffect(() => {
    console.log(`hasCounted state updated: ${hasCounted}`);
  }, [hasCounted]);

  useEffect(() => {
    console.log(`animationCompleted state updated: ${animationCompleted}`);
  }, [animationCompleted]);

  return (
    <div className="map-section-home-container">
      <div className={`map-section-home-landing-map-container`} ref={mapSectionRef}>
        <LandingMapWrapper
          onCountriesColored={handleCountriesColored}
          startAnimation={hasCounted}
        />
      </div>
      {animationCompleted && (
        <div className="map-section-home-text map-section-home-visible">
          <h2 className="map-section-home-global-presence-title">
            Creamos valor en 80 países y 6 continentes
          </h2>

          <div className="map-section-home-stats-container">
            <div className="map-section-home-stat-item">
              <p className="map-section-home-stat-number">
                {hasCounted ? <CountUp start={0} end={21} duration={2} /> : 0}
              </p>
              <p className="map-section-home-stat-label">Filiales</p>
            </div>
            <div className="map-section-home-stat-item">
              <p className="map-section-home-stat-number">
                {hasCounted ? <CountUp start={0} end={44} duration={2} /> : 0}
              </p>
              <p className="map-section-home-stat-label">Líneas de producción</p>
            </div>
            <div className="map-section-home-stat-item">
              <p className="map-section-home-stat-number">
                {hasCounted ? <CountUp start={0} end={460} duration={2} prefix="+" /> : 0}
              </p>
              <p className="map-section-home-stat-label">Productos líderes</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapSectionHome;
