import React, { useState, useEffect, useRef } from 'react';
import './ContactForm.css';
import { RevolvingDot } from 'react-loader-spinner';

const generateCaptcha = () => {
  const charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lengthOtp = 6;
  let captcha = [];
  for (let i = 0; i < lengthOtp; i++) {
    let index = Math.floor(Math.random() * charsArray.length);
    captcha.push(charsArray[index]);
  }
  return captcha.join("");
};

const ContactForm = ({ selectedOficina }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    pais: selectedOficina ? selectedOficina.pais : '',
    telefono: '',
    mensaje: '',
  });

  const [captchaCode, setCaptchaCode] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [captchaError, setCaptchaError] = useState('');
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  // Referencia al mensaje de agradecimiento
  const thankYouMessageRef = useRef(null);

  useEffect(() => {
    if (selectedOficina) {
      setFormData((prevData) => ({
        ...prevData,
        pais: selectedOficina.pais,
      }));
    }
  }, [selectedOficina]);

  useEffect(() => {
    setCaptchaCode(generateCaptcha());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaInput = (e) => {
    setCaptchaInput(e.target.value);
    if (e.target.value === captchaCode) {
      setIsCaptchaValid(true);
      setCaptchaError('');
    } else {
      setIsCaptchaValid(false);
      setCaptchaError('El CAPTCHA es incorrecto. Inténtalo de nuevo.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isSubmitEnabled) {
      alert('Por favor, complete el CAPTCHA antes de enviar.');
      return;
    }

    setIsSending(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFadeOut(true);
        setTimeout(() => {
          setIsSubmitted(true);
          // Si es un dispositivo móvil, desplazarse al mensaje de agradecimiento
          if (window.innerWidth <= 768) {
            thankYouMessageRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1000);
      } else {
        const errorData = await response.json();
        console.error('Error al enviar el email:', errorData);
        setIsSending(false);
        setIsSubmitEnabled(true);  // Reactivar los campos si hay error
      }
    } catch (error) {
      console.error('Error al enviar el email:', error);
      setIsSending(false);
      setIsSubmitEnabled(true);  // Reactivar los campos si hay error
    }
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    if (isCaptchaValid) {
      setIsModalOpen(false);
      setIsSubmitEnabled(true);
    }
  };

  return (
    <div className="contact-form-wrapper">
      {isSubmitted ? (
        <h3 ref={thankYouMessageRef} className="contact-form-thankyou fade-in">
          ¡Recibimos tu mensaje! <br /> En breve nos pondremos en contacto.
        </h3>
      ) : (
        <div className={`contact-form-content ${fadeOut ? 'fade-out' : ''}`}>
          <form onSubmit={handleSubmit} className="contact-form-form">
            <div className="contact-form-group">
              <label>Nombre:</label>
              <input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                placeholder="Ingresa tu nombre"
                required
                disabled={isSending}
              />
            </div>
            <div className="contact-form-group">
              <label>Correo Electrónico:</label>
              <input
                type="email"
                name="correo"
                value={formData.correo}
                onChange={handleInputChange}
                placeholder="Ingresa tu correo"
                required
                disabled={isSending}
              />
            </div>
            <div className="contact-form-group">
              <label>País:</label>
              <input
                type="text"
                name="pais"
                value={formData.pais}
                readOnly
                disabled={isSending}
              />
            </div>
            <div className="contact-form-group">
              <label>Teléfono:</label>
              <input
                type="text"
                name="telefono"
                value={formData.telefono}
                onChange={handleInputChange}
                placeholder="Ingresa tu número de teléfono"
                required
                disabled={isSending}
              />
            </div>
            <div className="contact-form-group">
              <label>Mensaje:</label>
              <textarea
                name="mensaje"
                value={formData.mensaje}
                onChange={handleInputChange}
                placeholder="Escribe tu mensaje"
                required
                disabled={isSending}
              />
            </div>

            {!isSending && (
              <>
                <div className="contact-form-checkbox-wrapper">
                  <div className="contact-form-checkbox-container">
                    <input
                      type="checkbox"
                      id="contact-form-captcha-checkbox"
                      checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                      disabled={isSending}
                    />
                    <label htmlFor="contact-form-captcha-checkbox">No soy un robot</label>
                  </div>
                </div>

                <div className="contact-form-submit-wrapper">
                  <button
                    type="submit"
                    className={isSubmitEnabled ? 'contact-form-button-enabled' : 'contact-form-button-disabled'}
                    disabled={!isSubmitEnabled}
                  >
                    Enviar
                  </button>
                </div>
              </>
            )}

            {isSending && (
              <div className="contact-form-loading-animation">
                <RevolvingDot
                  visible={true}
                  height="24"
                  width="24"
                  color="#AACE39"
                  ariaLabel="revolving-dot-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
          </form>

          {isModalOpen && (
            <div className="contact-form-modal-overlay">
              <div className="contact-form-modal">
                <span className="contact-form-close-modal" onClick={handleCloseModal}>
                  &times;
                </span>
                <h2>Completa el CAPTCHA</h2>
                <div className="contact-form-captcha-code">{captchaCode}</div>
                <input
                  type="text"
                  value={captchaInput}
                  onChange={handleCaptchaInput}
                  placeholder="Ingresa el CAPTCHA"
                />
                {captchaError && (
                  <div className="contact-form-captcha-error">{captchaError}</div>
                )}
                {isCaptchaValid && (
                  <button className="contact-form-button-continue" onClick={handleCloseModal}>
                    Continuar
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
