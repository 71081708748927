// src/components/LandingSerieSlider.js
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './LandingSerieSlider.css'; // Updated custom styles
import nextArrow from '../images/serieslider/next.png';
import prevArrow from '../images/serieslider/prev.png';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} landing-serie-slider-arrow landing-serie-slider-next`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next" className="landing-serie-slider-arrow-image" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} landing-serie-slider-arrow landing-serie-slider-prev`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Previous" className="landing-serie-slider-arrow-image" />
    </div>
  );
}

const LandingSerieSlider = ({ series, sliderRef, loading }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true, // Keep arrows on tablet and above
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false, // Disable arrows on smaller screens
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false, // Disable arrows on mobile
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="landing-serie-slider-background">
      <div className="landing-serie-slider-wrapper">
        <div className="landing-serie-slider">
          {!loading && (
            <Slider {...sliderSettings} ref={sliderRef}>
              {series.map((serie) => (
                <div key={serie.id} className="landing-serie-slider-slide">
                  <div className="landing-serie-slider-item">
                    <img src={serie.image} alt={serie.name} className="landing-serie-slider-image" />
                    <h3 className="landing-serie-slider-label">{serie.name}</h3>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingSerieSlider;
