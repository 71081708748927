import React, { useState, useEffect } from 'react';
import './ContactCard.css';
import { FaMapMarkerAlt } from 'react-icons/fa';

const ContactCard = ({ onOfficeChange }) => {
  const [oficinas, setOficinas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    const fetchOficinas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/oficinas?populate=*`);
        const result = await response.json();

        if (Array.isArray(result.data)) {
          const formattedData = result.data.map((oficina) => ({
            id: oficina.id,
            pais: oficina.attributes.pais,
            correo: oficina.attributes.correo,
            direccion: oficina.attributes.direccion,
            representante: oficina.attributes.representante,
            telefono: oficina.attributes.telefono,
            latitud: oficina.attributes.latitud,
            longitud: oficina.attributes.longitud
          }));

          // Asegurarse de que los datos estén ordenados antes de actualizar el estado
          setOficinas(formattedData.sort((a, b) => a.pais.localeCompare(b.pais)));

          if (!selectedCountry && formattedData.length > 0) {
            const initialOficina = formattedData[0];
            setSelectedCountry(initialOficina.pais);
            onOfficeChange(initialOficina);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error al obtener las oficinas:', error);
        setLoading(false);
      }
    };

    fetchOficinas();
  }, [onOfficeChange, selectedCountry]);

  const handleCountryChange = (event) => {
    const selectedOficina = oficinas.find(oficina => oficina.pais === event.target.value);

    if (selectedOficina) {
      setSelectedCountry(event.target.value);
      onOfficeChange(selectedOficina);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const filteredOficina = oficinas.find((oficina) => oficina.pais === selectedCountry);

  return (
    <div className="contact-card">
      <div className="contact-card-selector-container">
        <select
          id="contact-card-selector"
          className="contact-card-selector"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          {oficinas.map((oficina) => (
            <option key={oficina.id} value={oficina.pais}>
              {oficina.pais}
            </option>
          ))}
        </select>
      </div>

      {filteredOficina ? (
        <div className="contact-card-details">
          <div className="contact-card-header">
            <FaMapMarkerAlt className="contact-card-icono-localizacion" />
            <h3 className="contact-card-title">Información de sede</h3>
          </div>
          <ul>
            <li>Representante: {filteredOficina.representante}</li>
            <li>Teléfono: {filteredOficina.telefono}</li>
            <li>Dirección: {filteredOficina.direccion}</li>
            <li>Correo: {filteredOficina.correo}</li>
          </ul>
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default ContactCard;
