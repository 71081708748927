import React from 'react';
import LandingMapDesktop from './LandingMapDesktop';
import LandingMapMobile from './LandingMapMobile';

const LandingMapWrapper = ({ onCountriesColored, startAnimation }) => {
  const isMobile = window.innerWidth < 768;

  return (
    <>
      {isMobile ? (
        <LandingMapMobile onCountriesColored={onCountriesColored} startAnimation={startAnimation} />
      ) : (
        <LandingMapDesktop onCountriesColored={onCountriesColored} startAnimation={startAnimation} />
      )}
    </>
  );
};

export default LandingMapWrapper;
