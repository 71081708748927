// src/components/LandingBanner.js

import React from 'react';
import bannerImageDesktop from '../images/HomeBanner/homebanner-slide1.png';
import bannerImageMobile from '../images/HomeBanner/homebanner-slide1-mobile.png';
import LandingActionButton from '../components/LandingActionButton';
import './LandingBanner.css';

const LandingBanner = ({ scrollToContact }) => {
  const isMobile = window.innerWidth <= 600;

  return (
    <div className="landing-banner-container">
      <img
        src={isMobile ? bannerImageMobile : bannerImageDesktop}
        alt="Banner image for the landing page"
        className="landing-banner-image"
      />
      <div className="landing-banner-content">
        <h2 className="landing-banner-vision-label">VISIÓN QUE CREA FUTURO</h2>
        <p className="landing-banner-vision-sublabel">
          Maquinaria de alta calidad que integran tecnología, experiencia y sustentabilidad.
        </p>
        <LandingActionButton onClick={scrollToContact} />
      </div>
    </div>
  );
};

export default LandingBanner;
