import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useSpring, animated } from 'react-spring';
import './LandingMapDesktop.css';
import desktopPin from '../images/LandingMapDesktop/pin.png';

const highlightedCountryIds = [
  'RUS', 'GBR', 'BLR', 'USA', 'MEX',
  'BRA', 'ARG', 'IND', 'VNM', 'MMR',
  'IDN', 'MYS', 'PHL', 'THA', 'PAK',
  'KAZ', 'HKG', 'TZA', 'ZAF', 'EGY',
  'ARE', 'SAU', 'PER', 'CHL', 'COL'
];

function LandingMapDesktop({ onCountriesColored }) {
  const svgRef = useRef();
  const [countriesData, setCountriesData] = useState([]);
  const [highlighted, setHighlighted] = useState([]);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [showPins, setShowPins] = useState(false);

  const animationProps = useSpring({
    transform: animationStarted ? 'scale(0.5)' : 'scale(1)',
    config: { duration: 500 },
    onRest: () => {
      if (animationStarted) {
        console.log("Map scale animation completed.");
        setShowPins(true); // Show pins after scale animation completes
        if (onCountriesColored) {
          console.log("Calling onCountriesColored callback.");
          onCountriesColored();
        }
      }
    },
  });

  useEffect(() => {
    d3.json('https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson')
      .then(data => {
        const filteredData = data.features.filter(feature => feature.id !== 'ATA');
        setCountriesData(filteredData);
        console.log('Fetched and filtered countries data:', filteredData);
      })
      .catch(error => console.error("Error fetching geojson data: ", error));
  }, []);

  useEffect(() => {
    if (countriesData.length > 0 && startAnimation) {
      console.log("Starting to highlight countries.");
      highlightedCountryIds.forEach((countryCode, index) => {
        setTimeout(() => {
          setHighlighted(prev => {
            const newHighlighted = [...prev, countryCode];
            console.log(`Highlighting country: ${countryCode} at index: ${index}`);
            return newHighlighted;
          });
        }, index * 40);
      });

      setTimeout(() => {
        console.log("All countries highlighted. Starting scale animation.");
        setAnimationStarted(true);
      }, highlightedCountryIds.length * 40);
    }
  }, [countriesData, startAnimation]);

  useEffect(() => {
    if (countriesData.length > 0) {
      const svg = d3.select(svgRef.current);
      const { width, height } = svgRef.current.getBoundingClientRect();

      const projection = d3.geoNaturalEarth1()
        .scale(width / 5)
        .translate([width / 2.2, height / 1.6]);

      const pathGenerator = d3.geoPath().projection(projection);

      svg
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .selectAll('.country')
        .data(countriesData)
        .join('path')
        .attr('class', 'country')
        .attr('d', pathGenerator)
        .attr('fill', d => {
          const isHighlighted = highlighted.includes(d.id);
          return isHighlighted ? '#ABCF3A' : '#ccc';
        });

      if (showPins) {
        svg
          .selectAll('.pin')
          .data(countriesData.filter(d => highlighted.includes(d.id)))
          .join('image')
          .attr('class', 'pin')
          .attr('xlink:href', desktopPin)
          .attr('width', 15)
          .attr('height', 15)
          .attr('transform', d => {
            const [x, y] = projection(d3.geoCentroid(d));
            return `translate(${x - 7.5},${y - 7.5})`; // Offset to center the pin
          });
      }
    }
  }, [countriesData, highlighted, showPins]);

  useEffect(() => {
    const handleStartAnimation = () => {
      console.log("Received startAnimation event in LandingMapDesktop.");
      setStartAnimation(true);
    };

    document.addEventListener('startAnimation', handleStartAnimation);

    return () => {
      document.removeEventListener('startAnimation', handleStartAnimation);
    };
  }, []);

  return (
    <div className="landing-map-desktop-wrapper">
      <animated.div className="animated-map-desktop" style={animationProps}>
        <svg className="landing-map-desktop-svg" ref={svgRef} />
      </animated.div>
    </div>
  );
}

export default LandingMapDesktop;
