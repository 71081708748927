// src/components/ModelCard.js
import React from 'react';
import { Link } from 'react-router-dom'; // Para navegar a la ruta /contacto
import './ModelCard.css';

function ModelCard({ name, weight, power, capacity, image }) {
  return (
    <div className="model-card">
      <img src={image} alt={name} className="model-card-image" />
      <h3 className="model-card-name">{name}</h3>
      <h4 className="model-card-serie">{name}</h4>
      <p>Operating weight: {weight} kg</p>
      <p>Rated power: {power} kW</p>
      <p>Standard capacity: {capacity} m³</p>
      <p>Standard capacity: {capacity} m³</p>
      {/* Botón para consultar */}
      <div className="model-card-footer">
        <Link to="/contacto" className="model-card-button">
          CONSULTAR
        </Link>
      </div>
    </div>
  );
}

export default ModelCard;
