// src/pages/Productos.js
import React, { useEffect, useState } from 'react';
import PageBanner from '../components/PageBanner';
import ProductList from '../components/ProductList';
import ProductGrid from '../components/ProductGrid';
import PageFooter from '../components/PageFooter';
import './Productos.css';

function Productos() {
  const [lineas, setLineas] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState(null);
  const [selectedSerie, setSelectedSerie] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLineas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?populate=series`);
        const data = await response.json();
        if (data && data.data) {
          const fetchedLineas = data.data.map(linea => ({
            id: linea.id,
            name: linea.attributes.nombre,
            series: linea.attributes.series?.data.map(serie => ({
              id: serie.id,
              name: serie.attributes.nombre
            })) || []
          }));
          setLineas(fetchedLineas);
          setSelectedLinea(fetchedLineas[0]); // Seleccionamos la primera línea por defecto
          setSelectedSerie(fetchedLineas[0]?.series[0] || null); // Seleccionamos la primera serie por defecto
          setLoading(false);
        } else {
          console.error("Error al obtener las líneas:", data);
        }
      } catch (error) {
        console.error("Error fetching lineas:", error);
      }
    };

    fetchLineas();
  }, []);

  return (
    <div className="products-page-container">
      <div className="products-page-banner">
        <PageBanner />
      </div>
      {loading ? <div>Loading...</div> : (
        <div className="products-page-content">
          <ProductList 
            lineas={lineas} 
            selectedLinea={selectedLinea}
            setSelectedLinea={setSelectedLinea}
            selectedSerie={selectedSerie}
            setSelectedSerie={setSelectedSerie}
          />
          <ProductGrid />
        </div>
      )}
        <div className="products-page-footer">
        <PageFooter />
      </div>
    </div>
    
  );
}

export default Productos;
