import React from 'react';
import { Link } from 'react-router-dom';
import './HomeNavbar.css'; // Home-specific CSS
import logo from '../images/logo.webp';

const HomeNavbar = () => {
  return (
    <nav className="home-navbar">
      <div className="home-navbar-content">
        <div className="home-navbar-logo">
          <img src={logo} alt="Logo" />
        </div>
{/*  <div className="home-navbar-links">
          <Link to="/">HOME</Link>
          <Link to="/contacto">CONTACTO</Link>
          <Link to="/productos">PRODUCTOS</Link>
        </div> */}
      </div> 
    </nav>
  );
};

export default HomeNavbar;
