import React, { useRef } from 'react';
import './Home.css';
import LandingFooter from '../components/LandingFooter';
import LandingBanner from '../components/LandingBanner';
import LandingShowcase from '../components/LandingShowcase';
import MapSectionHome from '../components/MapSectionHome';
import LandingContact from '../components/LandingContact';


const Home = () => {
  // Create a ref for the LandingContact section
  const contactSectionRef = useRef(null);

  // Function to handle scroll to the LandingContact section
  const scrollToContact = () => {
    if (contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="section section1"><LandingBanner scrollToContact={scrollToContact} /></div>
      <div className="section section2"><MapSectionHome/></div>
      <div className="section section3"><LandingShowcase /></div>
      <div className="section section4" ref={contactSectionRef}><LandingContact/></div>
      <div className="section fp-auto-height">
        <LandingFooter />
      </div>
    </>
  );
};

export default Home;
