import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './PageBanner.css';

const PageBanner = () => {
  const location = useLocation();

  // Remove leading '/' and replace with '-' for the image filename
  const routeName = location.pathname.substring(1).replace('/', '-');
  const imagePath = `/images/pages/banner-${routeName}.png`;

  // Dictionary to map routes to titles
  const routeTitles = {
    productos: "PRODUCTOS",
    ofertalaboral: "OFERTA LABORAL",
    contacto: "CONTACTO",
    noticias: "NOTICIAS",
    servicios: "SERVICIOS",
    nosotros: "NOSOTROS",
  };

  const pageTitle = routeTitles[routeName] || "Page";

  useEffect(() => {
    console.log('Component Mounted');
    console.log('Current Location:', location.pathname);
    console.log('Banner Image Path:', imagePath);
    console.log('Page Title:', pageTitle);
  }, [location, imagePath, pageTitle]);

  // Check for the conditions where the banner should not be rendered
  if (location.pathname === '/' || (location.pathname.startsWith('/products/') && location.pathname !== '/products')) {
    console.log('Home page or subdirectory under /products detected, not rendering banner');
    return null; // Don't render on the home page or subdirectories under /products
  }

  return (
    <div
      className="page-banner"
      style={{
        backgroundImage: `url(${imagePath})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <h2 className="page-banner-title">{pageTitle}</h2>

      {/* Breadcrumb */}
      <div className="page-banner-breadcrumb">
        <Link to="/" className="page-banner-breadcrumb-link">Inicio</Link>
        <span className="page-banner-breadcrumb-divider">/</span>
        <span className="page-banner-breadcrumb-current">{pageTitle}</span>
      </div>
    </div>
  );
};

export default PageBanner;
