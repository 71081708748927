// src/components/LandingShowcase.js
import React, { useEffect, useState, useRef } from 'react';
import LandingLineaSlider from './LandingLineaSlider';
import LandingSerieSlider from './LandingSerieSlider';
import './LandingShowcase.css'; // Updated custom styles

function LandingShowcase() {
  const [lineas, setLineas] = useState([]);
  const [currentLinea, setCurrentLinea] = useState("");
  const [loading, setLoading] = useState(true);
  const lineaSliderRef = useRef(null);
  const serieSliderRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    fetchLineas();
  }, []);

  useEffect(() => {
    if (lineas.length > 0) {
      setCurrentLinea(lineas[0].id); // Set the first linea as the current linea
      setLoading(false);
    }
  }, [lineas]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchLineas = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?populate=icono_landing,series,series.imagen_landing`);
        const data = await response.json();
        if (data && data.data) {
            const fetchedLineas = data.data.map(item => ({
                id: item.id,
                name: item.attributes.nombre,
                image: item.attributes.icono_landing?.data?.attributes?.url || "",  // Correctly accessing icono_landing
                series: item.attributes.series?.data.map(serie => ({
                    id: serie.id,
                    name: serie.attributes.nombre,
                    image: serie.attributes.imagen_landing?.data?.[0]?.attributes?.url || ""  // Correctly accessing imagen_landing
                })) || []
            }));
            setLineas(fetchedLineas);
        } else {
            console.error("Lineas data is not structured as expected:", data);
        }
    } catch (error) {
        console.error("Error fetching lineas:", error);
    }
  };

  const handleLineaChange = (index) => {
    const selectedLinea = lineas[index];
    if (selectedLinea) {
      setCurrentLinea(selectedLinea.id);
      if (serieSliderRef.current) {
        serieSliderRef.current.slickGoTo(0);  // Use slickGoTo to reset the SerieSlider to the first slide
      }
    }
  };

  const currentLineaSeries = lineas.find(linea => linea.id === currentLinea)?.series || [];

  return (
    <div className="landing-showcase-container">
      {/* Conditionally render the h2 element only on mobile */}
      {isMobile && <h2 className="landing-showcase-title">PRODUCTOS</h2>}
      <LandingLineaSlider 
        lineas={lineas} 
        onLineaChange={handleLineaChange} 
        sliderRef={lineaSliderRef} 
        loading={loading} 
      />
      <LandingSerieSlider 
        series={currentLineaSeries} 
        sliderRef={serieSliderRef} 
        loading={loading} 
      />
      {loading && <div>Loading...</div>}
    </div>
  );
}

export default LandingShowcase;
