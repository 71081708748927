import React from 'react';
import PageBanner from '../components/PageBanner'; // Assuming you have this component available
import LandingFooter from '../components/LandingFooter'; // Assuming you have this component available
import ServiciosAccordeon from '../components/ServiciosAccordeon'; // Import the accordion component
import './Servicios.css'; // The corresponding CSS file

const Servicios = () => {
  return (
    <div className="servicios-page">
      <div className="servicios-page-banner">
        <PageBanner />
      </div>

      {/* Description Section */}
      <div className="servicios-page-descripcion">
        <div className="servicios-page-descripcion-item">
          <img src="/images/servicios/servicios-card-1.png" alt="Service" className="servicios-page-img" />
          <p className="servicios-page-text">
          El lema de Zoomlion es "la visión crea el futuro". No es solo un simple lema, sino una determinación inquebrantable de superar las expectativas de nuestros clientes. 
          Zoomlion siempre ha mantenido esta creencia y trata de implementarla en todo lo que hacemos por nuestros clientes. 
          El servicio que ofrecemos a los clientes es pensar en lo que ellos piensan y preocuparnos por lo que les preocupa.
          </p>
        </div>
        <div className="servicios-page-descripcion-item">
          <img src="/images/servicios/servicios-card-2.png" alt="Service" className="servicios-page-img" />
          <p className="servicios-page-text">
          Zoomlion promete que, una vez que nos elijas, nuestra empresa y el ZOOMLION CARE 
          cuatro en uno estarán listos para responder con la primera solución disponible en cualquier momento y lugar.
          </p>
        </div>
        <div className="servicios-page-descripcion-item">
          <img src="/images/servicios/servicios-card-3.png" alt="Service" className="servicios-page-img" />
          <p className="servicios-page-text">
          Diversos contenidos de servicio, equipos de servicio profesionales, redes de servicio globales y soporte técnico sistemático están disponibles de inmediato por parte de Zoomlion 
          para brindar una respuesta y solución efectiva a tu solicitud, sin importar dónde te encuentres en el mundo ni la hora que sea.
          </p>
        </div>
      </div>

      {/* Accordion Section */}
      <ServiciosAccordeon />

      {/* Contact Section */}
      <div className="service-page-contact" style={{ backgroundImage: `url(/images/servicios/banner-servicios.jpg)` }}>
        <button className="service-page-contact-button" onClick={() => window.location.href = '/contacto'}>
          CONTÁCTANOS
        </button>
      </div>

      <div className="servicios-page-footer">
        <LandingFooter />
      </div>
    </div>
  );
};

export default Servicios;
