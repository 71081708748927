import React from 'react';
import './ContactMap.css'; // Import the CSS file

const ContactMap = ({ oficina }) => {
  const lat = oficina?.latitud || 40.7128; // Convertimos de latitud a lat
  const lng = oficina?.longitud || -74.0060; // Convertimos de longitud a lng

  const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}&z=13&output=embed`;

  return (
    <div className="contact-map-container">
      <iframe
        src={googleMapsUrl}
        allowFullScreen=""
        loading="lazy"
        title="Office Location Map"
      />
    </div>
  );
};

export default ContactMap;
