// src/components/LandingActionButton.js

import React from 'react';
import './LandingActionButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const LandingActionButton = () => {
    const handleClick = () => {
        if (window.fullpage_api) {
            window.fullpage_api.moveTo(4);
        }
    };

    return (
        <div className="landing-action-container" onClick={handleClick}>
            <div className="landing-action-content">
                <span>CONTÁCTANOS</span>
            </div>
            <div className="landing-action-arrow">
                <FontAwesomeIcon icon={faAngleRight} className="arrow-icon" />
            </div>
        </div>
    );
};

export default LandingActionButton;
