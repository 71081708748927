import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './LandingLineaSlider.css';
import nextArrow from '../images/lineaslider/next.png';
import prevArrow from '../images/lineaslider/prev.png';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} landing-linea-slider-arrow landing-linea-slider-next`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next" className="landing-linea-slider-arrow-image" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} landing-linea-slider-arrow landing-linea-slider-prev`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Previous" className="landing-linea-slider-arrow-image" />
    </div>
  );
}

const LandingLineaSlider = ({ lineas, onLineaChange, sliderRef, loading }) => {
  // Initialize with the first item as selected
  const [selectedLinea, setSelectedLinea] = useState(0);
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleLineaClick = (index) => {
    setSelectedLinea(index);
    onLineaChange(index);
    setClickedIndex(index);
    setTimeout(() => setClickedIndex(null), 300); // Shrinking animation reset after 300ms
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1440, settings: { slidesToShow: 7, arrows: true } },
      { breakpoint: 1280, settings: { slidesToShow: 6, arrows: true } },
      { breakpoint: 1024, settings: { slidesToShow: 6, arrows: true } },
      { breakpoint: 768, settings: { slidesToShow: 4, arrows: true } },
      { 
        breakpoint: 640, 
        settings: {
          className: 'center', 
          slidesToShow: 3,
          centerPadding: '1rem',
          centerMode: true,
          arrows: false, 
          afterChange: index => handleLineaClick(index)  // Trigger onClick effect on mobile
        } 
      },
    ],
  };

  return (
    <div className="landing-linea-slider-wrapper">
      <div className="landing-linea-slider">
        {!loading && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {lineas.map((linea, index) => (
              <div key={linea.id} onClick={() => handleLineaClick(index)}>
                <button
                  className={`landing-linea-slider-slide-button ${clickedIndex === index ? 'clicked' : ''}`}
                >
                  <div
                    className={`landing-linea-slider-item ${
                      selectedLinea === index ? 'selected' : ''
                    }`}
                  >
                    <img
                      src={linea.image}
                      alt={linea.name}
                      className="landing-linea-slider-image"
                    />
                    <h3 className="landing-linea-slider-label">{linea.name}</h3>
                  </div>
                </button>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default LandingLineaSlider;
